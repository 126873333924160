<template>
  <div id="logo" class="logo">
    <template v-if="!(pano.isPro&&pano.hideLogo)">
      <a v-if="mp" href="javascript:" @click="click">
        <img src="/images/logo.png" @load="$parent.$parent.$forceUpdate()">
      </a>
        <nuxt-link v-else to="/">
          <img src="/images/logo.png" @load="$parent.$parent.$forceUpdate()">
        </nuxt-link>
    </template>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      pano: {
        default: {}
      },
    },
    data() {
      return {
        mp:null
      }
    },
    inject: {
      pano: {
        default: {}
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
      this.mp = this.$mp.getMP()
    },
    destroyed() {
    },
    methods: {
      click() {
        this.$mp.navigateTo()
      }
    },
  }
</script>
<style scoped>
  .logo {
    pointer-events: auto !important;
  }

    .logo img {
      width: 130px;
    }
</style>
